// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import {AuthProvider} from "react-oidc-context";
import {KEYCLOAK_CONFIG} from "./config";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  return (
      <ThemeCustomization>
        <RTLLayout>
          <Locales>
            <ScrollTop>
              <AuthProvider {...KEYCLOAK_CONFIG} onSigninCallback={user => {
                window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname
                )
              }}>
                <>
                  <Routes/>
                  <Snackbar/>
                </>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        </RTLLayout>
      </ThemeCustomization>
  );
}

export default App;
